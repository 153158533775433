import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"
import Table from "../components/Table/table"
import StartOverButton from "../components/StartOverButton/StartOverButton"

import { OCCUPATION_TABLE } from "../types/types"

const OccupationTable = () => (
  <Layout>
    <SEO title="Occupation Table" />
    <StartOverButton />
    <Table tableType={OCCUPATION_TABLE} />
  </Layout>
)

export default OccupationTable
